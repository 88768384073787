<template>
  <OnboardingStep
    :title="$t('onboarding.storeSetupView.title')"
    :next-text="$t('onboarding.storeSetupView.nextText')"
    :next-to="nextTo"
  >
    <StoreView :store-id="storeId" :show-new-store="false" />
  </OnboardingStep>
</template>

<script>
import { BaseStepView } from "./BaseStepView";
import StoreView from "@/views/Settings/StoreView/Index.vue";

export default {
  name: "StoreSetupView",
  components: { StoreView },
  extends: BaseStepView,
};
</script>
